$(function() {
	console.log("LOAD REFRESHERz");

	$("#setup_refresh .scene-dragger").draggable({
		appendTo: "body",
		helper: "clone",
		scroll: false,
		revert: true,
		classes: "dragging",
		containment: "window",
		revertDuration: 0,
		stop: function(event, ui) {
		}
	});
	
	function moveToHeap(item) {
		var row = $("<tr></tr>").prependTo("#heap tbody");
		var col = $("<td></td>").prependTo(row);
		item.prependTo(col);
	}
	
	$("#setup_refresh .scene-dropper").droppable({
		hoverClass: "hover-target",
        drop: function(event, ui) {
			var targetRow = $(this);
			
			// if there's already something there...
			const oldItem = targetRow.find(".scene-dragger")
			if(oldItem.length) {
				// add a new empty row after this one
				var newRow = $('<tr class="scene-dropper"><td></td><td class="scene-box"></td></tr>').insertAfter(targetRow);
				targetRow = newRow;
			}
			
			// move the item to this slot
			targetRow.find(".scene-box").prepend(ui.draggable);
        }
    });
	
	$("#setup_refresh #heap").droppable({
		hoverClass: "hover-target",
		drop: function(event, ui) {
			moveToHeap(ui.draggable);
		}
	});
	
	$("#refresh-form").on("submit", function(event) {
		console.log("GO REFRESH");
				
		var mappings = [];
		
		$(".scene-box").each(function(row) {
			var thisMapping = [$(this).data("old-scene-id"), $(this).find(".scene-dragger").data("scene-id")];
			mappings.push(thisMapping);
		});
		
		fetch("/productions/" + productionId + "/schedules/" + scheduleId + "/scenes/refresh",
		{
			body: JSON.stringify({
				other_schedule_id: otherScheduleId,
				mapping: mappings
			}),
			method: "post"
		});
		
		console.log("ARRAY IS: " + JSON.stringify(mappings));
		
		$("#mapping-field").val(JSON.stringify(mappings));
	});
});