$(function() {	
	$(".shoot-day-checkbox").click(function() {
		addShootDays();
	});
	
	function addShootDays() {
		var pagesSum = 0;
		var minutesSum = 0;
		
		$(".shoot-day-checkbox:checked").each(function() {
			pagesSum += parseInt($(this).data("eighths"), 10);
			minutesSum += parseInt($(this).data("minutes"), 10);
		});
		$("#page-count").text(eighthsToDisplay(pagesSum));
		$("#time-estimate").text(minutesToTime(minutesSum));
	}
});

function eighthsToDisplay(eighths) {
	if(eighths == null || eighths == 0) {
		return '0';
	} else if(eighths < 8) {
		return eighths + " / 8";
	} else if(eighths % 8 == 0) {
		return "" + eighths / 8;
	} else {
		return Math.floor(eighths / 8) + " " + eighths % 8 + " / 8";
	}
}


function minutesToTime(minutes) {
	return Math.floor(minutes / 60) + ":" + (minutes % 60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
}